import ESTJ from '../person_img/진도준_.jpg'; //ESTJ
import ESFP from '../person_img/레이첼_.jpg'; //ESFP
import ENTJ from '../person_img/진양철_.jpg'; //ENTJ
import INTP from '../person_img/오세현_.jpg'; //INTP
import INTJ from '../person_img/서민영_.jpg'; //INTJ
import ISTJ from '../person_img/윤현우_.jpg'; //ISTJ
import ESTP from '../person_img/진동기_.jpg'; //ESTP
import INFP from '../person_img/진영기_.jpg'; //INFP
import ISFP from '../person_img/진윤기_.jpg'; //ISFP
import ISFJ from '../person_img/이항재_.jpg'; //ISFJ
import ENFP from '../person_img/진형준_.jpg'; //ENFP
import INFJ from '../person_img/이필옥_.jpg'; //INFJ
import ESFJ from '../person_img/최창제_.jpg'; //ESFJ
import ENTP from '../person_img/진화영_.jpg'; //ENTP
import ISTP from '../person_img/모현민_.jpg'; //ISTP
import ENFJ from '../person_img/이해인_.jpg'; //ENFJ
import styled from 'styled-components';
import './resultText.css'


export const ResultText = (id) => {
  switch (id) {
    case 1: // 재벌집 막내아들 진도준
      return (
    
        <DescSub>
          "내가 진도준의 몸으로 태어난 건 빙의도, 시간여행도, 환생도 아니다. 이번 생은 나에게 기회다."<br/><br/>
          
          
          <span className='personality'>타고난 사업가형입니다. 도전 정신이 강하고 현실 감각이 뛰어나며 일을 조직하고 계획하여 추진시키는 능력이 탁월합니다.</span><br/>
          순양그룹을 손에 넣는다는 목표를 세우고 순양백화점부터 순양카드 등 지분 매수를 계획적으로 성공시켜 나갑니다. <br/>
          <span className='personality'>남의 디테일한 장점과 단점을 잘 캐치해 내는 능력이 있습니다.</span> 순양가와 관련된 모든 사람들의 약점을 빠르게 파악하고 본인에게 유리하게 활용해 내는 능력이 출중합니다.<br/>
          <span className='personality'>계획적으로 움직이는 것을 좋아해요. 심지어 데이트를 할 때도 말이죠.</span> 서민영 검사와 데이트할 때도 사실 순양그룹을 갖겠다는 목표 달성을 위해 계획적으로 만난 적도 있으니까 말이죠. <br/>
          <span className='personality'>외로움을 별로 타지 않고 겉보기에 철저하고 엄격해 보이지만 가까운 사람에게는 의리와 배려심이 가득합니다.</span><br/>
          서민영 검사와 진양철 회장이랑도 지지고 볶으며 지내는가 싶지만 사실 인간적으로 유대감을 쌓고 상대의 입장을 많이 생각한 말과 행동을 보여줍니다.<br/><br/>
          당신, 다시 태어나면 '사기캐' 혹은 적어도 엄친아/엄친딸 소리 듣는 자식으로 태어나겠는데요?<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>오세현, 모현민</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>진영기, 이필옥</center><br/>
        </DescSub>
  );
  
  case 2: // 미라클 애널리스트 레이첼
    return (
      <DescSub>
        "디카프리오? 대체 연기는 누가 하는 거냐. 스타 배우 한 명도 없는 블록버스터가 진심 성공할 수 있다고 믿어요, 보스?" <br/><br/>


        <span className='personality'>열정과 에너지 넘치는 연예인형입니다.</span> 참 재밌게도 레이첼을 연기한 티파니 역시 에너지 넘치는 연예인이죠. <span className='personality'>어떤 상황이든 잘 적응하고 현실적인 감각과 관찰력이 탁월합니다.</span><br/>
        <span className='personality'>어떤 일이든 주저하지 않고 일단 도전해 보는 성격을 갖고 있습니다. </span>덕분에 미라클에서 어디로 튈지 모르는 진도준과 오세현 대표와 함께 누구도 감당하기 어려운 도전을 성공적으로 이끄는 데 기여했죠.<br/>
        <span className='personality'>좋고 싫음이 얼굴에 다 티가 나는 유형이에요.</span> 보스인 오세현 대표가 개봉을 앞둔 영화 타이타닉에 천만 달러를 투자한다 했을 때 불신하죠. 정말 당장이라도 오세현을 손절할 것만 같았어요.<br/><br/>
        "당신, 다시 태어났다면 여의도 큰 손 애널리스트로 불렸겠는데요?"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>윤현우, 오세현</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>진영기, 이필옥</center><br/>
      </DescSub>
    );

  case 3: // 재벌집 진양철 회장
    return (
      <DescSub>
        "내가 제일로 사랑하는 자식이 누군지, 니 모르나? 순양이다." <br/><br/>
        <span className='personality'>타고난 지도자형입니다. 완벽주의, 결단력, 책임감이 뛰어나</span> 대한민국 경제사에 굵직한 획을 그은 순양그룹을 창업하였죠.<br/>
        <span className='personality'>인기 있는 사람보다 능력 있는 사람으로 여겨지길 바라고, 소속감보다는 본인 역량을 발휘해 자기개발과 성취감을 중요시합니다.</span><br/>
        <span className='personality'>감정표현이 솔직한 편이라</span> 자식은 물론 손주에게도 못 하는 소리가 없죠. 삼남 진윤기가 돈도 안 되는 영화 사업한다 했다가 자식으로도 취급도 안 할 정도니까요.<br/>
        <span className='personality'>현재에 안주하기보다는 미래 계획을 세우고 빠른 추진력으로 일을 빠르게 성공시키는 능력이 있습니다. </span><br/>
        주변에서 만류하던 반도체 사업을 미래가치에 투자하고 추진시켜 대성공시킨 업적도 세웠죠.<br/><br/>
        "당신, 다시 태어나면 적어도 대한민국 10대 재벌 총수 자리에는 있겠는데요?<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>진영기, 진도준</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>레이첼, 진형준</center><br/>
      </DescSub>

    )
    
  case 4: // 미라클 대표 오세현
      return (
        <DescSub>
          "자신 없는 거래는 시작을 하는 게 아닙니다. 알아들어요? 동포청년?" <br/><br/>

          <span className='personality'>논리술사라 불릴 만큼 논리와 분석력이 뛰어납니다. 비과학적이거나 논리적이지 못한 사람이나 사건에 대해 상당히 부정적이에요.</span><br/>
          그래서 동포청년 진도준이 2002년 월드컵에서 4강을 간다느니, 911테러가 발생할 걸 예견한 것처럼 전날까지 해외주식을 정리해야 된다는 얘기를 당최 믿음이 안 가죠. 논리도 없고 과학적이지도 않으니까요. <br/>
          <span className='personality'>상상력과 호기심이 넘치는 성격이라 브레인스토밍처럼 다양한 가능성에 대해 대화하는 것을 선호해요.</span><br/>
          그러니 시도 때도 없이 진도준과 어떤 종목의 주식을 사야 되니, 팔아야 되니하면서 열띤 토의가 이뤄지죠.<br/>
          <span className='personality'>맨날 예민하고 말 많은 사람으로 비칠 수 있지만, 활기차게 토론하고 아이디어를 공유할 수 있는 일만큼 즐거워하는 일도 없다고 생각하는 유형입니다.</span><br/><br/>
          "당신, 다시 태어난다면 투자 식견이 좋은 금융투자사 대표 자리 하나 차지하고 있겠는데요?"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>진양철, 진도준</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>최창제, 윤현우</center><br/>

        </DescSub>

      )
      
  case 5: // 검사 서민영
      return (
        <DescSub>
          "재판부 판결을 존중해서 증거보강하고 항소해야죠 꼭 유죄 받아서 실형 살려야죠. 대한민국 법치국가에요. 순양 공화국이 아니라."<br/><br/>


          <span className='personality'>질서있는 추론을 사용하여 관심있는 문제를 해결</span>하는 당신은 법을 기반으로 순양을 집요하게 추적하는 순양의 저승사자 서민영입니다 ! <br/>
          <span className='personality'>지능이나 지식에 대한 고집, 욕심이 넘쳐</span>서 법대 최고 학부를 나온 당신은 법과 같은 <span className='personality'>어려운 학문을 즐기는</span>군요.<br/>
          <span className='personality'>현재에 멈추지 않고 자신의 지식과 능력을 향상시킬 방법을 매 순간 찾아 나서는</span> 당신은 
          <span className='personality'>과정보다 결과를 중요시</span>하여 무엇이 이유가 됐든 도준의 할머니 해외 도피 거래를 이해하지 못합니다. <br/>
          <span className='personality'>자기 확신 또는 자기 능력에 대한 전망을 미래지향적으로 좋게 바라보는</span> 당신은 초임검사로 지내면서 제대로 된 사건 하나 배당받지 못해도 검사로서 밥값하게 해달라며 끝까지 그 
          <span className='personality'>노력을 놓지 않고, 책벌레 같은 면모</span>를 보이며 순양백화점 압수수색 수사까지 진행합니다.<br/>
          <span className='personality'>사회성이 없는 편은 아니나 아무에게나 시간과 에너지를 투자하지 않는 당신은 많은 지인보다는 소수의 좋은 친구를 사귀는군요. </span><br/>
          남자친구인 도준에게도 칭찬이 인색한 걸 보면 <span className='personality'>생각보다 칭찬은 잘하지 않는 성격입니다.</span> <br/><br/>
          "당신, 다시 태어났다면 적어도 잘생기고, 똑똑하고, 돈많은, 영앤리치앤핸섬올뷰티 애인 한 명쯤 있었겠는데요?"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>진형준, 이해인</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>최창제, 이항재</center><br/>
        </DescSub>

      )
  
  case 6: // 순양 재무팀장 윤현우
    return (
      <DescSub>
        "내가 절대 하지 않는 세 가지가 있습니다. 오너 일가의 지시는 절대 거절하지 않습니다. 그 어떤 질문도 하지 않습니다. 그리고 그 모든 경우에 결코 판단하지 않습니다. 내 원칙입니다."<br/><br/>


    <span className='personality'>주어진 업무나 책임은 끝까지 완수하는</span> 당신은 순양 오너 일가의 리스크를 관리하는 순양물산 미래자산관리팀 팀장 윤현우입니다! <br/>
    <span className='personality'>원리, 원칙적으로 행동하는</span> 당신은 세 가지의 원칙을 바탕으로 행동하며, 순양 핸드폰을 사용하지 않는 부사수에게 눈치를 주기도 하네요!<br/>
    <span className='personality'>남들은 속을 모르겠다고 말하는</span>데 부사수는 <span className='personality'>실수한 것을 바로 수정하는</span> 당신의 성격을 어찌 알고, 바로 순양폰으로 바꿔와서 다행입니다..!<br/>
    <span className='personality'>예고없이 갑작스러운 변화를 굉장히 싫어하고, 논리적, 합리적인 것을 중요시하지만</span> 오너 일가의 <span className='personality'>구시대적인 관습을 논리에 따른 판단없이도 수용하는 편</span>이네요.<br/>
    동생이 한 명, 첫째인 당신은 <span className='personality'>의젓한 성격이라 장남, 장녀같다는 말을 많이 들으며, 휴일에도 쉴 때는 보통 집에서 지내는 편입니다.</span><br/>
    아버지 대신 가장 노릇을 하며 울분을 토하는 모습은 <span className='personality'>참고 참았다가 터뜨리는</span> 당신의 성격을 잘보여줍니다!<br/><br/>
    
    "당신, 다시 태어났다면 또 다시 태어나셔야겠는데요..?"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>이항재, 진도준</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>진영기, 진형준</center><br/>

      </DescSub>
    )

  case 7: // 재벌집 차남 진동기
    return (
      <DescSub>
        <span className='first_comment'>"아버지! 왜 나만! 나만 맨날 이렇게 맨날 들러리예요, 왜!"</span><br/><br/>

        <span className='personality'>모험과 스릴을 즐기는 사업가로서 현재에 집중해 생각을 실행에 옮기는 능력이 탁월합니다.</span> 장남 진영기에게 순양그룹을 뺏기지 않기 위해 빠르게 금융계열사 분리를 실행에 옮기죠.<br/>
        <span className='personality'>계산이 빠르고 잔머리의 대가이지만 충동적이고 즉흥적인 성격이 있습니다.</span> 9.11 테러로 해외 주식 투자한 금액 대부분을 날리자 손해를 메꾸려고 100억을 풋옵션에 넣습니다.하락장에 배팅한 것이죠. 하지만 그 돈마저도 날립니다. <br/>
        <span className='personality'>새로운 것에 대한 도전욕구와 성취욕이 강합니다.</span> 순양그룹 총수가 되기 위해 물불 가리지 않습니다.<br/>
        한도제철을 노리는 진양철 회장과 장남 진영기를 이용해 대영그룹과 몰래 컨소시엄을 구성하여 잇속을 챙기려 했지만 술책이 다 뽀록나서 무산됐습니다.<br/><br/>
        "당신, 다시 태어나면 만년 들러리 신세를 벗어나기 위한 나만의 노하우가 필요하겠는데요?"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>이항재, 윤현우</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>이해인, 진형준</center><br/>

      </DescSub>
    )
  case 8: // 재벌집 장남 진영기
    return (
      <DescSub>
         <span className='first_comment'>"비 온다 성준아, 하늘이 참 변덕스러워. 비위 맞추기 힘드네."</span><br/><br/>

         <span className='personality'>자신이 지향하는 이상에 대해서는 정열적인</span>  당신은 넘치는 의욕, 그러나 부진으로 그룹 승계후보 1순위이지만 입지를 다지지 못한순양그룹 장남 진영기입니다!<br/>
         <span className='personality'>자신에게 의미있는 가치나 사람들에게 충성</span>하여, 아버지 진양철을 대신해 감옥살이를 하는 등 <span className='personality'>소수의 특별한 사람들과 매우 깊게 관계 가지는</span> 당신. 옥살이 때문에 무릎 고질병까지 얻게 되다니, <span className='personality'>마음이 따뜻하나 친해지기 전까지는 자신의 따뜻함이나 마음을 잘 표현하지 않고 조용하고 과묵한 성격입니다.</span><br/>
         <span className='personality'>상상력이 풍부해서, 두뇌 회전이 빠르고 이해력이 높은</span> 당신은, 빠르게 도준의 후계자 싸움 상황을 인식하고 후계자 자리를 빼앗길 것에 대한 두려움에 쌓입니다. 집안의 <span className='personality'> 복잡한 상황은 잘 견디지만, 반복되는 일에는 인내심이 없는</span> 당신은, 도준의 반복되는 후계자 후보 상황에 <span className='personality'>걱정이 많습니다.</span><br/>
          결국 <span className='personality'>빠른 상황 적응력</span>으로 금융지주 주주총회에서 유리한 상황을 가져오지만, <span className='personality'>개개인이 평범한 길보다 각자만의 길을 찾아가야 한다 생각하는 개인주의자 성격</span>으로, 동생 진동기와 함께 손을 잡아도, 배신 당할까봐 경계하네요.<br/><br/>

          "당신, 다시 태어났다면 나라를 이끄는 그룹의 장남으로, 한 건 하시겠는데요?"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>진양철, 이필옥</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>진윤기, 진도준</center><br/>
      </DescSub>
    )
  
  case 9: //재벌집 삼남 진윤기
    return (
      <DescSub>
        "자식이 뭘 원하는지 뻔히 알면서도 내 아들로 태어난 이상 할아버지의 뜻을 어기는 일은 시작도 하지 말라고 말할 수밖에 없는 게 내 처지잖아. 미안해서. 이런 아버지라."<br/><br/>
        <span className='personality'>연락하기 귀찮지만 연락 안오면 섭섭해하는, 관심받기 싫어하지만 관종인 모순덩어리</span> 당신은 순양그룹의 미운털 서자 진윤기입니다! <br/>
        순양그룹에서도 재산 분쟁에 참여하지 않으려 하는 당신은 <span className='personality'>사람들과 싸우는 걸 싫어하고 조화롭게 지내고자 노력하지만,</span> 도준이로 분쟁에 참여하게 되며 그냥 넘어가려 하지 않는 모습을 통해 <span className='personality'>모든 걸 대충하지만 또 하면 완벽히 하려는 성향</span>임을 알 수 있습니다.<br/>
        <span className='personality'>화를 잘 안내서 착하다는 얘기를 많이 듣는</span> 당신은, <span className='personality'>남을 먼저 배려하고,</span> 순양가에서도 순한 편에 속하며, <span className='personality'>넌 어떻게 하고 싶은건지 정확하게 말해봐! 하고 강요하는 것을 싫어합니다.</span><br/>
        <span className='personality'>누군가에게 인정받고 칭찬 받는 것을 좋아</span>하지만, 순양 가에서는 어느 하나 쉽지 않네요. 순양가의 삼남이며 주인공의 아버지인데도 그렇게 자주 나오지 않는 걸 보면 <span className='personality'>개인의 시간이 중요한 것 같습니다.</span><br/><br/>
        "당신, 다시 태어났다면 사기캐 아들 하나 두겠는데요?"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>이해인, 진도준</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>진영기, 이필옥</center><br/>
      </DescSub>
    )
    
  case 10: // 재벌집 비서실장 이항재
    return (
      <DescSub>
        "도준 군이 유언장의 의미를 깨달으면 그 때 전해주라고 당부하셨습니다" <br/><br/>

        <span className='personality'>전통과 조직에 충실한 관리적인 성격의</span> 당신은 진양철 회장의 옆에서 순양을 함께 키워낸 순양그룹 비서실장 이항재입니다! <br/>
        <span className='personality'>눈치가 빨라서 상대방에게 잘맞춰주는 성격의</span> 당신은 자식들도 모르는 진양철 회장의 심정을 누구보다 잘 이해하는 사람입니다.<br/>
        중요한 결정을 함께 상의했을 정도로 <span className='personality'>다른 사람의 고민을 잘 들어주는 공감왕</span>이며, 자신의 <span className='personality'>역할을 완수하기 위해 뒤에서 열심히 일하는 성격</span>입니다.
        <span className='personality'>차분하고 조용한, 온화한 성격으로</span> 진양철이 죽고나서 수정된 유언장으로 자식들이 소리를 지르며 화를 내도 침착한 것처럼 <span className='personality'>화를 잘 내지 않지만 한 번 화나면 무서우니 조심해야 합니다.</span><br/>
        <span className='personality'>진중하고 장기적인 관계를 좋아하는</span> 당신은 <span className='personality'>신중하고 모든 일에 꼼꼼히 계획하고 생각하며</span> 비서실장다운 면모를 보여주고, 도준에게 순양 마이크로 비자금을 넘겨주며 <span className='personality'>개인적인 가치에 따라 행동하며 양심적인 성격과 헌신적이고 책임감이 강한 면모</span>를 볼 수 있습니다.<br/><br/>
        "당신, 다시 태어났다면 큰 기업의 회장에게 가장 믿음을 받는 사람이었겠는데요?"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>윤현우, 진도준</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>진영기, 이필옥</center><br/>

      </DescSub>
    )
  
    case 11: // 재벌집 날라리 진형준
      return (
        <DescSub>
          "내가 뭐 우리나라 축구 대표팀이 월드컵 4강 진출이라도 한다했어? 꿈도 못 꿔, 어? 야, 꿈은 이루어진다 몰라?" <br/><br/>

          <span className='personality'>인정과 관심을 받길 강력하게 원하고 타인의 시선에 민감해요. 가끔 관종이라는 소리도 듣곤 하죠.</span><br/>
          순양가의 엄숙한 저녁 식사 자리에서 HOT 코스프레 차림으로 앉아있질 않나, 집안 싸움 중에도 아랑곳하지 않고 춤을 추는 등 <span className='personality'>개성이 넘쳐 흐릅니다.</span><br/>
          <span className='personality'>계획하기보다는 그때그때 일을 처리하는 편이며, 작은 일에도 감동을 잘 하고 눈물도 잘 흘립니다.</span><br/>
          아버지가 셋째로 태어나 순양그룹 승계가 어려워 가슴 아파하는 데 가수 오디션에 합격했다며 혼자 엉뚱하게 울기 시작하는 모습도 보이죠.<br/>
          <span className='personality'>통찰력과 상상력이 풍부하여 항상 새로운 가능성을 찾고 시도하는 유형입니다.</span> 미래에 BTS가 빌보드 핫100 1위에 등극할 것도, 월드컵에서 우리나라 축구팀이 최초로 4강까지 진출할 것을 예견합니다.<br/><br/>
          "당신, 다시 태어났다면 본인을 슈퍼스타라고 생각하는 관종끼 폭발 연예인이지 않았을까요?"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>이해인, 오세현</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>진동기, 모현민</center><br/>
          
        </DescSub>
      )

    case 12: // 재벌집 안주인 이필옥
      return (
        <DescSub>
          "알잖아요, 나 평생 당신에게 할 수 있는 최선을 다했어요"<br/><br/>

          <span className='personality'>만나는 사람에 따라 맞춰주려고 노력하는</span> 당신은 순양 가문의 안주인 이필옥입니다!<br/>
          순양 그룹 안주인인 만큼 아는 사람이 적지 않겠지만, 내 사람은 가족들뿐인 당신은 <span className='personality'>주위에 아는 사람은 많아서 외향인일 것 같다는 얘기를 정말 많이 듣지만, 정작 내 사람이라고 생각하는 사람은 몇 없는</span> 성격이군요.<br/>
          <span className='personality'>실제로 혼자만의 여유가 중요한</span> 당신은 진양철 회장이 치매에 걸리자 "이제야 내 사람이 되셨네..." 라는 무서운 명대사를 남깁니다..(넘무섭)<br/>
          <span className='personality'>앞에서는 사람들에게 티내지 않고 묵묵하게 있다가, 뒤에서 능력을 발휘하는 </span>당신은 자식들 아무도 모르게 순양 생명 지분을 쌓아놓지만, <span className='personality'>속마음을 남에게 잘 얘기하지 않는 성격</span>으로 진도준에게 지분을 넘기고 난 이후 큰아들한테도 욕을 먹습니다.. <br/>
          <span className='personality'>그렇지만 가족에게 따뜻하게 대해주고, 다른 사람들을 잘 이해해주는</span> 사람이네요.<br/><br/>
          "당신, 다시 태어났다면 남몰래 큰 지분을 쌓고 계시겠는데요 ? 그렇다면 저도 좀.."<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>진화영, 진영기</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>윤현우, 진도준</center><br/>
        </DescSub>
      )
    
    case 13: // 젠틀 서울시장 최창제
      return (
        <DescSub>
          "천만 서울시민의 목소리를 듣겠습니다!" <br/><br/>
          <span className='personality'>원칙주의자입니다.</span> 아무리 처가댁이라도 순양그룹 금융지주 설립으로 금산분리 원칙 위배를 지켜만 볼 수 없죠.<br/>
          <span className='personality'>기브&테이크를 중요시</span>해서 도준이 원하는 거를 들어준 덕분에 서울시장 자리까지 오게 되었죠. <span className='personality'>참을성이 많고 다른 사람을 잘 도와주는 유형입니다.</span><br/>
          순양댁에서 장인어른 진양철 회장과 와이프 진화영에게 촌놈 검사 사위로 매번 무시받아도 꾹 참고 집안을 서포트했었죠.<br/>
          <span className='personality'>타인의 인정을 받는 것에 아주 민감하고 돈보다는 사회적 지위, 권위, 명예를 추구하는 편입니다.</span><br/>
          재벌집 검사 사위보다는 스스로와 남들이 인정해 주는 서울시장, 대통령이 되는 게 목표인 것처럼 말이죠.<br/>
          <span className='personality'>귀는 얇은데 옳고 그름을 날카롭게 구분합니다.</span> 도준이 말에 쉽게 넘어가 하라는 대로 다 하는 편입니다.<br/>
          그럼에도 진양철 회장님이 아무리 무서워도 할 말은 시원하고 논리적으로 얘기하게 되었죠(도준이 덕분에).<br/><br/>
          "당신, 다시 태어나면 적어도 구청장 자리 하나는 차지하고 있겠는데요?"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>진윤기, 모현민</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>진영기, 진형준</center><br/>

        </DescSub>
      )
    case 14: // 재벌집 장녀 진화영
      return (
        <DescSub>
            "알려드려야겠구나, 아버지한테. 당신 딸이 고명이 아니고 메인디쉬라고!"<br/><br/>

            <span className='personality'>내 자신이 최고예요.</span> 나도 순양의 후계자가 될수 있어! 맏이가 아니라고, 여자라고 안되는건 없어요.<br/>
            <span className='personality'>궁금한게 많지만 속으론 이미 자신만의 정답을 생각하고 있습니다. 겉에서 보면 변덕꾸러기 같아보여요.<br/>
            보기엔 자신의 뜻을 이루기위해 싸우지만 사실은 혼자 생각도 많고 속이 여린, 자신만의 기댈곳이 필요해요.</span><br/>
            과외선생님이였고 자신의 남편이자 서울시장인 최창제가 버팀목 역할을 든든히 해주고 있던걸까요?<br/><br/>
            
            "당신, 자신의 고집을 조금만 꺾으면 충분히 메인디쉬가 될수 있다는 사실을 알고 계신가요?"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>이필옥, 서민영</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>이항재, 진도준</center><br/>

        </DescSub>
      )

    case 15: // 재벌집 며느리 모현민
      return (
        <DescSub>
          "그 애가, 순양의 주인이 되는 걸 방해한다면, 난 그게 누구든 뭐든 할 수 있어 당신 할머니처럼. 그러니까 똑바로 해."<br/><br/>
          <span className='personality'>주관과 자기 주장이 뚜렷한</span> 당신은 그 난장판인 순양 그룹 가에서 <span className='personality'>자신의 실익 하나만을 뚜렷히 바라보고 직진하는</span> 모현민입니다!<br/>
          <span className='personality'>일반적으로 조용한 편이나 필요에 따라 사교적이기도 한</span> 당신은 <span className='personality'>선의의 거짓말이나 변명보다 직설적으로 말하는 성격</span>으로, 임신 사실을 알자마자 아이가 순양그룹 후계자가 되는 것을 위해 뭐든 지 할 수 있다고 알리네요.<br/>
          <span className='personality'>나한테 하는 투자는 아깝지 않은 당신은 자기 행동을 정당화하는 편</span>이라 앞으로 어떤 행적을 당당히 할 지 걱정됩니다.<br/>
          <span className='personality'>많은 사람들과 지내는 것보다 혼자 있는 시간이 더 편하고, 이불 속, 충전하는 시간이 소중한</span> 당신은 벌써 진성준에게 느낀 것처럼 <span className='personality'>쉽게 지루함과 무감각함을 느끼기도 합니다.</span><br/>
          <span className='personality'>타인에 무관심하지만</span> DMC 사건으로 진성준이 도준을 때릴 뻔하자 기절하면서 상황을 마무리시키는 <span className='personality'>위기 상황에 대처가 훌륭합니다.</span><br/><br/>
          "당신, 다시 태어났다면 지금이 위기에요..!"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>최창제, 진도준</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>진영기, 진형준</center><br/>
        </DescSub>
      )
    
    case 16: //재벌집 도준엄마 이해인
      return (
        <DescSub>
          "두 사람 나한테 약속해. 도준이 너, 너희 친가 식구들 앞으로도 닮지 않겠다고. 당신은 우리 도준이 지켜줘. 당신 아버지나 형제들이 우리 아들 상처 주지 못하게."<br/><br/>
          <span className='personality'>사람들을 너무 좋아하고 정도 많고 애정도 많은</span> 당신은 순양제국의 이방인, <span className='personality'>순수한 영혼</span>인 도준의 어머니 이해인입니다!<br/>
          <span className='personality'>다른 사람을 잘 험담하지 않는</span> 당신은 <span className='personality'>연민과 동정, 이해심이 많아</span> 순양가 내에서도 힘들지만 잘 버텨내는 인물입니다.
          <span className='personality'>타인의 관심에 귀 기울이며 배려하여, 사람과의 관계를 이상적으로 생각하는 경향</span>이 있어, <span className='personality'>오지랖이 넓다</span>는 평가를 가끔 받기도 합니다.<br/>
          그러나 <span className='personality'>직관적으로 사람을 파악하는 데 능한</span> 당신은 아들에게 무슨 일이 생긴건지 알려주는 사람에게 지분 3%를 양도하겠다고 말할 정도로, <span className='personality'>자신이 사랑하는 사람을 위해선 적극적으로 돕고 전폭적인 지원을 아끼지 않는군요.</span><br/>
          <span className='personality'>살기 좋은 공동체를 만들기 위해 사람들을 동참시키고 이끄는데 큰 능력이 있는</span> 당신은 도준이 순양그룹 승계 전쟁에 참여한다는 것을 듣고,<br/>
          도준이와 남편에게도 서로를 위할 수 있도록 약속시키며, <span className='personality'>상대방을 설득하는 능력 그리고 정의로운 사람이라는 걸 알 수 있습니다.</span><br/>
          그러니 <span className='personality'>자기 반성과 자책을 자기 발전의 원동력으로 삼는</span> 것은 줄여도 좋을 것 같습니다. :) <br/><br/>
          "당신, 다시 태어났다면 냉정한 순양그룹 집안의 꼭 필요한 빛과 소금이었겠네요!"<br/><br/><br/>
          <center>🙏환상의 콜라보🙏</center><br/>
          <center>서민영, 오세현</center><br/><br/>
          <center>🔥환장의 콜라보🔥</center><br/>
          <center>모현민, 최창제</center><br/>
          
        </DescSub>
      )
  }
}

export const ResultData = [
  {
    id: 1,
    token: 'cf4175fd7b',
    name: '재벌집 막내아들 진도준',
    best: 'ESTJ',
    desc: ' \"내가 진도준의 몸으로 태어난 건 빙의도, 시간여행도, 환생도 아니다. 이번 생은 나에게 기회다.\" \n\n\
    타고난 사업가형입니다. 도전 정신이 강하고 현실 감각이 뛰어나며 일을 조직하고 계획하여 추진시키는 능력이 탁월합니다. \n\
    순양그룹을 손에 넣는다는 목표를 세우고 순양백화점부터 순양카드 등 지분 매수를 계획적으로 성공시켜 나갑니다. \n\
    남의 디테일한 장점과 단점을 잘 캐치해 내는 능력이 있습니다. 순양가와 관련된 모든 사람들의 약점을 빠르게 파악하고 본인에게 유리하게 활용해 내는 능력이 출중합니다. \n\
    계획적으로 움직이는 것을 좋아해요. 심지어 데이트를 할 때도 말이죠. 서민영 검사와 데이트할 때도 사실 순양그룹을 갖겠다는 목표 달성을 위해 계획적으로 만난 적도 있으니까 말이죠. \n\
    외로움을 별로 타지 않고 겉보기에 철저하고 엄격해 보이지만 가까운 사람에게는 의리와 배려심이 가득합니다. \n\
    서민영 검사와 진양철 회장이랑도 지지고 볶으며 지내는가 싶지만 사실 인간적으로 유대감을 쌓고 상대의 입장을 많이 생각한 말과 행동을 보여줍니다.\n\n\
    \"당신, 다시 태어나면 \'사기캐\' 혹은 적어도 엄친아/엄친딸 소리 듣는 자식으로 태어나겠는데요?\"',
    image: ESTJ,
  },
  {
    id: 2,
    token: 'ad0bbf2599',
    name: '미라클 애널리스트 레이첼',
    best: 'ESFP',
    desc: '\"디카프리오? 대체 연기는 누가 하는 거냐. 스타 배우 한 명도 없는 블록버스터가 진심 성공할 수 있다고 믿어요, Boss?\" \n\n\
    열정과 에너지 넘치는 연예인형입니다. 참 재밌게도 레이첼을 연기한 티파니 역시 에너지 넘치는 연예인이죠. 어떤 상황이든 잘 적응하고 현실적인 감각과 관찰력이 탁월합니다. \n\
    어떤 일이든 주저하지 않고 일단 도전해 보면 성격을 갖고 있습니다. 덕분에 미라클에서 어디로 튈지 모르는 진도준과 오세현 대표와 함께 누구도 감당하기 어려운 도전을 성공적으로 이끄는 데 기여했죠. \n\
    좋고 싫음이 얼굴에 다 티가 나는 유형이에요. Boss인 오세현 대표가 개봉을 앞둔 영화 타이타닉에 천만 달러를 투자한다 했을 때 불신하는 데 당장이라도 오세현을 손절할 것만 같았어요. \n\n\
    \"당신, 다시 태어났다면 현실적 감각과 관찰력으로 투자왕으로 불렸겠는데요?\"',
    image: ESFP
  },
  {
    id: 3,
    token: '40e482eaa0',
    name: '재벌집 진양철 회장',
    best: 'ENTJ',
    desc: '\"내가 제일로 사랑하는 자식이 누군지, 니 모르나? 순양이다.\" \n\n\
    타고난 지도자형입니다. 완벽주의, 결단력, 책임감이 뛰어나 대한민국 경제사에 굵직한 획을 그은 순양그룹을 창업하였죠. \n\
    인기 있는 사람보다 능력 있는 사람으로 여겨지길 바라고, 소속감보다는 본인 역량을 발휘해 자기개발과 성취감을 중요시합니다. \n\
    감정표현이 솔직한 편이라 자식은 물론 손주에게도 못 하는 소리가 없죠. \n\
    삼남 진윤기가 돈도 안 되는 영화 사업한다 했다가 자식으로도 취급도 안 할 정도니까요. \n\
    현재에 안주하기보다는 미래 계획을 세우고 빠른 추진력으로 일을 빠르게 성공시키는 능력이 있습니다. \n\
    주변에서 만류하던 반도체 사업을 미래가치에 투자하고 추진시켜 대성공시킨 업적도 세웠죠. \n\n\
    \"당신, 다시 태어나면 적어도 대한민국 10대 재벌 총수 자리에는 있겠는데요?\"',
    image: ENTJ,
  },
  
  {
    id: 4,
    token: 'a42b7064df',
    name: '미라클 대표 오세현',
    best: 'INTP',
    desc: '\"자신 없는 거래는 시작을 하는 게 아닙니다. 알아들어요? 동포청년?\" \n\n \
    논리술사라 불릴 만큼 논리와 분석력이 뛰어납니다. 비과학적이거나 논리적이지 못한 사람이나 사건에 대해 상당히 부정적이에요. \n \
    그래서 동포청년 진도준이 2002년 월드컵에서 4강을 간다느니, 911테러가 발생할 걸 예견한 것처럼 전날까지 해외주식을 정리해야 된다는 얘기를 당최 믿음이 안 가죠. \n\
    논리도 없고 과학적이지도 않으니까요. 상상력과 호기심이 넘치는 성격이라 브레인스토밍처럼 다양한 가능성에 대해 대화하는 것을 선호해요.  \n\
    그러니 시도 때도 없이 진도준과 어떤 종목의 주식을 사야 되니, 팔아야 되니하면서 열띤 토의가 이뤄지죠. \n\
    맨날 예민하고 말 많은 사람으로 비칠 수 있지만, 활기차게 토론하고 아이디어를 공유할 수 있는 일만큼 즐거워하는 일도 없다고 생각하는 유형입니다. \n\n\
    \"당신, 다시 태어난다면 투자 식견이 좋은 금융투자사 대표 자리 하나 차지하고 있겠는데요?\"',
    image: INTP
  },
  {
    id: 5,
    token: '64334b1b4a',
    name: '검사 서민영',
    best: 'INTJ',
    desc: '\"재판부 판결을 존중해서 증거보강하고 항소해야죠 꼭 유죄 받아서 실형 살려야죠. 대한민국 법치국가에요. 순양 공화국이 아니라.\" \n\n \
    질서있는 추론을 사용하여 관심있는 문제를 해결하는 당신은 법을 기반으로 순양을 집요하게 추적하는 순양의 저승사자 서민영입니다 !\n \
    지능이나 지식에 대한 고집, 욕심이 넘쳐서 법대 최고 학부를 나온 당신은 법과 같은 어려운 학문을 즐기는군요 \n \
    현재에 멈추지 않고 자신의 지식과 능력을 향상시킬 방법을 매 순간 찾아 나서는 당신은 과정보다 결과를 중요시하여 무엇이 이유가 됐든 도준의 할머니 해외 도피 거래를 이해하지 못합니다.\n \
    자기 확신 또는 자기 능력에 대한 전망을 미래지향적으로 좋게 바라보는 당신은 초임검사로 지내면서 제대로 된 사건 하나 배당받지 못해도 검사로서 밥값하게 해달라며 끝까지 그 노력을 놓지 않고, \n \
    책벌레 같은 면모를 보이며 순양백화점 압수수색 수사까지 진행합니다. 사회성이 없는 편은 아니나 아무에게나 시간과 에너지를 투자하지 않는 당신은 많은 지인보다는 소수의 좋은 친구를 사귀는군요. \n \
    남자친구인 도준에게도 칭찬이 인색한 걸 보면 생각보다 칭찬은 잘하지 않는 성격입니다. \n\n \
    \"당신, 다시 태어났다면 적어도 잘생기고, 똑똑하고, 돈많은, 영앤리치앤핸섬올뷰티 애인 한 명쯤 있었겠는데요?\" ',
    image: INTJ
  },
  {
    id: 6,
    token: 'd2d63f789a',
    name: '순양 재무팀장 윤현우',
    best: 'ISTJ',
    desc: '\"내가 절대 하지 않는 세 가지가 있습니다. 오너 일가의 지시는 절대 거절하지 않습니다. 그 어떤 질문도 하지 않습니다. 그리고 그 모든 경우에 결코 판단하지 않습니다. 내 원칙입니다.\" \n\n\
    주어진 업무나 책임은 끝까지 완수하는 당신은 순양 오너 일가의 리스크를 관리하는 순양물산 미래자산관리팀 팀장 윤현우입니다 !\n\
    원리, 원칙적으로 행동하는 당신은 세 가지의 원칙을 바탕으로 행동하며, 순양 핸드폰을 사용하지 않는 부사수에게 눈치를 주기도 하네요 !\n\
    남들은 속을 모르겠다고 말하는데 부사수는 실수한 것을 바로 수정하는 당신의 성격을 어찌 알고, 바로 순양폰으로 바꿔와서 다행입니다..!\n\
    예고없이 갑작스러운 변화를 굉장히 싫어하고, 논리적, 합리적인 것을 중요시하지만 오너 일가의 구시대적인 관습을 논리에 따른 판단없이도 수용하는 편이네요.\n\
    동생이 한 명, 첫째인 당신은 의젓한 성격이라 장남, 장녀같다는 말을 많이 들으며, 휴일에도 쉴 때는 보통 집에서 지내는 편이고, \n\
    아버지 대신 가장 노릇을 하며 울분을 토하는 모습은 참고 참았다가 터뜨리는 당신의 성격을 잘보여줍니다!\n\n\
    \"당신, 다시 태어났다면 또 다시 태어나셔야겠는데요..?\"',
    image: ISTJ
  },
  {
    id: 7,
    token: 'b3b1d185e4',
    name: '재벌집 차남 진동기',
    best: 'ESTP',
    desc: '\"아버지! 왜 나만! 나만 맨날 이렇게 맨날 들러리예요, 왜!\" \n\n\
    모험과 스릴을 즐기는 사업가로서 현재에 집중해 생각을 실행에 옮기는 능력이 탁월합니다. 장남 진영기에게 순양그룹을 뺏기지 않기 위해 빠르게 금융계열사 분리를 실행에 옮기죠.\n\
    계산이 빠르고 잔머리의 대가이지만 충동적이고 즉흥적인 성격이 있습니다. 9.11 테러로 해외 주식 투자한 금액 대부분을 날리자 손해를 메꾸려고 100억을 풋옵션에 넣습니다. \n\
    하락장에 배팅한 것이죠. 하지만 그 돈마저도 날립니다. 새로운 것에 대한 도전욕구와 성취욕이 강합니다. 순양그룹 총수가 되기 위해 물불 가리지 않습니다. \n\
    한도제철을 노리는 진양철 회장과 장남 진영기를 이용해 대영그룹과 몰래 컨소시엄을 구성하여 잇속을 챙기려 했지만 술책이 다 뽀록나서 무산됐습니다. \n\n\
    \"당신, 다시 태어나면 만년 들러리 신세를 벗어나기 위한 나만의 노하우가 필요하겠는데요?\"',
    image: ESTP
  },
  {
    id: 8,
    token: '0838bab7a4',
    name: '재벌집 장남 진영기',
    best: 'INFP',
    desc: '\"비 온다 성준아, 하늘이 참 변덕스러워. 비위 맞추기 힘드네.\"\n\n\
    진양철회장의 첫째아들이자 진도준의 큰아버지. 그룹 승계후보 1순위이지만 그 입지를 단단히 하지 못하고 흔들립니다.\n\
    넘치는 의욕에 딸리는 실력. 안타깝게도 진양철의 탁월한 사업감각과 명민함은 물려받지 못했습니다.\n\
    자연스레 진양철의 시선은 진도준에게 향하고 진영기는 그의 앞길을 방해하는 자는 그 누구도 용서할 수 없습니다. 설령 혈육인 가족이라도\n\
    자신이 지향하는 이상에 대해서는 정열적인 신념을 지니고 있다고 볼 수 있습니다.\n\
    자기 남매들이나 진도준과 기업 승계 경쟁을 할 때나 아랫사람을 대할 때 냉혹한 모습을 자주 보이는데, 아버지 진양철에게서 물려받은 기질인 듯 합니다. \n\
    다만 아버지만큼의 명민함이나 자질은 갖추지 못했고 아버지와는 다르게 자기 감정 조절에 능숙하지도 않기 때문에 냉혹한 성향이 아버지처럼 장점으로 발휘되는 경우는 거의 없었습니다.\n\n\
    \"시크 츤츤한 당신안에 숨어있는 냉혹함을 잘 조절한다면 당신의 이상에 가까워질 수도...\"',
    image: INFP
  },
  {
    id: 9,
    token: 'ad998fcad0',
    name: '재벌집 삼남 진윤기',
    best: 'ISFP',
    desc: '\"자식이 뭘 원하는지 뻔히 알면서도 내 아들로 태어난 이상 할아버지의 뜻을 어기는 일은 시작도 하지 말라고 말할 수밖에 없는 게 내 처지잖아. 미안해서. 이런 아버지라.\"\n\n\
    연락하기 귀찮지만 연락 안오면 섭섭해하는, 관심받기 싫어하지만 관종인 모순덩어리 당신은 순양그룹의 미운털 서자 진윤기입니다 !\n\
    순양그룹에서도 재산 분쟁에 참여하지 않으려 하는 당신은 사람들과 싸우는 걸 싫어하고 조화롭게 지내고자 노력하지만, 도준이로 분쟁에 참여하게 되며 그냥 넘어가려 하지 않는 모습을 통해 모든 걸 대충하지만 또 하면 완벽히 하려는 성향임을 알 수 있습니다.\n\
    화를 잘 안내서 착하다는 얘기를 많이 듣는 당신은, 남을 먼저 배려하고, 순양가에서도 순한 편에 속하며, 넌 어떻게 하고 싶은건지 정확하게 말해봐 ! 하고 강요하는 것을 싫어합니다.\n\
    누군가에게 인정받고 칭찬 받는 것을 좋아하지만, 순양 가에서는 어느 하나 쉽지 않네요. 순양가의 삼남이며 주인공의 아버지인데도 그렇게 자주 나오지 않는 걸 보면 개인의 시간이 중요한 것 같습니다.\n\
    \"당신, 다시 태어났다면 좋은 아들 하나 두겠는데요?\"',
    image: ISFP
  },
  {
    id: 10,
    token: '15789b70e2',
    name: '재벌집 비서실장 이항재',
    best: 'ISFJ',
    desc: '\"도준 군이 유언장의 의미를 깨달으면 그 때 전해주라고 당부하셨습니다\"\n\n\
    전통과 조직에 충실한 관리적인 성격의 당신은 진양철 회장의 옆에서 순양을 함께 키워낸 순양그룹 비서실장 이항재입니다 !\n\
    눈치가 빨라서 상대방에게 잘맞춰주는 성격의 당신은 자식들도 모르는 진양철 회장의 심정을 누구보다 잘 이해하는 사람입니다.\n\
    차분하고 조용한, 온화한 성격으로 진양철이 죽고나서 수정된 유언장으로 자식들이 소리를 지르며 화를 내도 침착한 것처럼 화를 잘 내지 않지만 한 번 화나면 무서우니 조심해야 합니다.\n\
    진중하고 장기적인 관계를 좋아하는 당신은 신중하고 모든 일에 꼼꼼히 계획하고 생각하며 비서실장다운 면모를 보여주고, 도준에게 순양 마이크로 비자금을 넘겨주며 개인적인 가치에 따라 행동하며 양심적인 성격과 헌신적이고 책임감이 강한 면모를 볼 수 있습니다.\n\n\
    \"당신, 다시 태어났다면 큰 기업의 회장에게 가장 믿음을 받는 사람이었겠는데요?\"',
    image: ISFJ
  },
  {
    id: 11,
    token: 'bfaf148cd7',
    name: '재벌집 날라리 진형준',
    best: 'ENFP',
    desc: '\"내가 뭐 우리나라 축구 대표팀이 월드컵 4강 진출이라도 한다했어? 꿈도 못 꿔, 어? 야, 꿈은 이루어진다 몰라?\" \n\n\
    인정과 관심을 받길 강력하게 원하고 타인의 시선에 민감해요. 가끔 관종이라는 소리도 듣곤 하죠. \n\
    순양가의 엄숙한 저녁 식사 자리에서 HOT 코스프레 차림으로 앉아있질 않나, 집안 싸움 중에도 아랑곳하지 않고 춤을 추는 등 개성이 넘쳐 흐릅니다. \n\
    계획하기보다는 그때그때 일을 처리하는 편이며, 작은 일에도 감동을 잘 하고 눈물도 잘 흘립니다. \n\
    아버지가 셋째로 태어나 순양그룹 승계가 어려워 가슴 아파하는 데 가수 오디션에 합격했다며 혼자 엉뚱하게 울기 시작하는 모습도 보이죠. \n\
    통찰력과 상상력이 풍부하여 항상 새로운 가능성을 찾고 시도하는 유형입니다. 미래에 BTS가 빌보드 핫100 1위에 등극할 것도, 월드컵에서 우리나라 축구팀이 최초로 4강까지 진출할 것을 예견합니다. \n\n\
    \"당신, 다시 태어났다면 본인을 슈퍼스타라고 생각하는 관종끼 폭발 연예인이지 않았을까요?\"',
    image: ENFP
  },
  {
    id: 12,
    token: '82791fa00c',
    name: '재벌집 안주인 이필옥',
    best: 'INFJ',
    desc: '\"알잖아요, 나 평생 당신에게 할 수 있는 최선을 다했어요\"\n\n\
    만나는 사람에 따라 맞춰주려고 노력하는 당신은 순양 가문의 안주인 이필옥입니다!\n\
    순양 그룹 안주인인 만큼 아는 사람이 적지 않겠지만, 내 사람은 가족들뿐인 당신은 주위에 아는 사람은 많아서 외향인일 것 같다는 얘기를 정말 많이 듣지만, 정작 내 사람이라고 생각하는 사람은 몇 없는 성격이군요.\n\
    실제로 혼자만의 여유가 중요한 당신은 진양철 회장이 치매에 걸리자 ""이제야 내 사람이 되셨네..."" 라는 무서운 명대사를 남깁니다..(넘무섭)\n\
    앞에서는 사람들에게 티내지 않고 묵묵하게 있다가, 뒤에서 능력을 발휘하는 당신은 자식들 아무도 모르게 순양 생명 지분을 쌓아놓지만, 속마음을 남에게 잘 얘기하지 않는 성격으로 진도준에게 지분을 넘기고 난 이후 큰아들한테도 욕을 먹습니다.. 그렇지만 가족에게 따뜻하게 대해주고, 다른 사람들을 잘 이해해주는 사람이네요.\n\n\
    \"당신, 다시 태어났다면 남몰래 큰 지분을 쌓고 계시겠는데요 ? 그렇다면 저도 좀..\"',
    image: INFJ
  },
  {
    id: 13,
    token: '6ab5e4b717',
    name: '젠틀 서울시장 최창제',
    best: 'ESFJ',
    desc: '\"천만 서울시민의 목소리를 듣겠습니다 \" \n\n\
    원칙주의자입니다. 아무리 처가댁이라도 순양그룹 금융지주 설립으로 금산분리 원칙 위배를 지켜만 볼 수 없죠. \n\
    기브&테이크를 중요시해서 도준이 원하는 거를 들어준 덕분에 서울시장 자리까지 오게 되었죠. 참을성이 많고 다른 사람을 잘 도와주는 유형입니다. \n\
    순양댁에서 장인어른 진양철 회장과 와이프 진화영에게 촌놈 검사 사위로 매번 무시받아도 꾹 참고 집안을 서포트했었죠. \n\
    타인의 인정을 받는 것에 아주 민감하고 돈보다는 사회적 지위, 권위, 명예를 추구하는 편입니다. \n\
    재벌집 검사 사위보다는 스스로와 남들이 인정해 주는 서울시장, 대통령이 되는 게 목표인 것처럼 말이죠. \n\
    귀는 얇은데 옳고 그름을 날카롭게 구분합니다. 도준이 말에 쉽게 넘어가 하라는 대로 다 하는 편입니다. \n\
    그럼에도 진양철 회장님이 아무리 무서워도 할 말은 시원하고 논리적으로 얘기하게 되었죠(도준이 덕분에). \n\n\
    \"당신, 다시 태어나면 적어도 구청장 자리 하나는 차지하고 있겠는데요?\"',
    image: ESFJ
  },
  {
    id: 14,
    token: '61f4343a13',
    name: '재벌집 장녀 진화영',
    best: 'ENTP',
    desc: '\"알려드려야겠구나, 아버지한테. 당신 딸이 고명이 아니고 메인디쉬라고\"\n\n\
    내 자신이 최고예요 나도 순양의 후계자가 될수 있어! 맏이가 아니라고, 여자라고 안되는건 없어요.\n\
    궁금한게 많지만 속으론 이미 자신만의 정답을 생각하고 있습니다. 겉에서 보면 변덕꾸러기 같아보여요\n\
    보기엔 자신의 뜻을 이루기위해 싸우지만 사실은 혼자 생각도 많고 속이 여린, 자신만의 기댈곳이 필요해요.\n\
    과외선생님이였고 자신의 남편이자 서울시장인 최창제가  버팀목 역할을 든든히 해주고 있던걸까요?\n\n\
    \"당신, 자신의 고집을 조금만 꺾으면 충분히 메인디쉬가 될수 있다는 사실을 알고 계신가요?\"',
    image: ENTP
  },
  {
    id: 15,
    token: 'bdd7320e1e',
    name: '재벌집 며느리 모현민',
    best: 'ISTP',
    desc: '\"그 애가, 순양의 주인이 되는 걸 방해한다면, 난 그게 누구든 뭐든 할 수 있어 당신 할머니처럼. 그러니까 똑바로 해.\"\n\n\
    주관과 자기 주장이 뚜렷한 당신은 그 난장판인 순양 그룹 가에서 자신의 실익 하나만을 뚜렷히 바라보고 직진하는 모현민입니다!\n\
    일반적으로 조용한 편이나 필요에 따라 사교적이기도 한 당신은 선의의 거짓말이나 변명보다 직설적으로 말하는 성격으로, 임신 사실을 알자마자 아이가 순양그룹 후계자가 되는 것을 위해 뭐든 지 할 수 있다고 알리네요.\n\
    나한테 하는 투자는 아깝지 않은 당신은 자기 행동을 정당화하는 편이라 앞으로 어떤 행적을 당당히 할 지 걱정됩니다.\n\
    많은 사람들과 지내는 것보다 혼자 있는 시간이 더 편하고, 이불 속, 충전하는 시간이 소중한 당신은 벌써 진성준에게 느낀 것처럼 쉽게 지루함과 무감각함을 느끼기도 합니다.\n\
    타인에 무관심하지만 DMC 사건으로 진성준이 도준을 때릴 뻔하자 기절하면서 상황을 마무리시키는 위기 상황에 대처가 훌륭합니다.\n\n\
    \"당신, 다시 태어났다면 지금이 위기에요..!\"',
    image: ISTP
  },
  {
    id: 16,
    token: '0ac11dbcca',
    name: '재벌집 도준엄마 이해인',
    best: 'ENFJ',
    desc: ' \"두 사람 나한테 약속해. 도준이 너, 너희 친가 식구들 앞으로도 닮지 않겠다고. 당신은 우리 도준이 지켜줘. 당신 아버지나 형제들이 우리 아들 상처 주지 못하게.\" \n\n \
    사람들을 너무 좋아하고 정도 많고 애정도 많은 당신은 순양제국의 이방인, 순수한 영혼의 도준의 어머니 이해인입니다 ! \n \
    다른 사람을 잘 험담하지 않는 당신은 연민과 동정, 이해심이 많아 순양가 내에서도 힘들지만 잘 버텨내는 인물입니다. 타인의 관심에 귀 기울이며 배려하여, \n \
    사람과의 관계를 이상적으로 생각하는 경향이 있어, 오지랖이 넓다는 평가를 가끔 받기도 합니다. \n \
    그러나 직관적으로 사람을 파악하는 데 능한 당신은 아들에게 무슨 일이 생긴건지 알려주는 사람에게 지분 3%를 양도하겠다고 말할 정도로 자신이 사랑하는 사람을 위해선 적극적으로 돕고, \n \
    전폭적인 지원을 아끼지 않는군요. \n \
    살기 좋은 공동체를 만들기 위해 사람들을 동참시키고 이끄는데 큰 능력이 있는 당신은 도준이 순양그룹 승계 전쟁에 참여한다는 것을 듣고, \n \
    도준이와 남편에게도 서로를 위할 수 있도록 약속시키며, 상대방을 설득하는 능력 그리고 정의로운 사람이라는 걸 알 수 있습니다. \n \
    그러니 자기 반성과 자책을 자기 발전의 원동력으로 삼는 것은 줄여도 좋을 것 같습니다. :) \n\n \
    \" 당신, 다시 태어났다면 냉정한 순양그룹 집안의 꼭 필요한 빛과 소금이었겠네요!\" ',
    image: ENFJ
  },

]

const DescSub = styled.div`
  font-size: 15px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5px;
  white-space: pre-wrap;
  font-family: "Welcome_Regular";

`