import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResultData, ResultText } from '../assets/data/resultdata';
import KakaoShareBtn from '../component/KakaoShareBtn';
import Footer from './footer';
import axios from 'axios';
import Adfit from "./Adfit";
import { UrlAPI } from './parameter';
import "../assets/fonts/font.css"
import TestNYRimg from '../assets/nyr-title.png';
import TestKTAimg from '../assets/kta-title.png';

let dateString;

const Result = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const URLsplitted = window.location.href.split('result/');
  const URLparams = URLsplitted[1];
  const [resultData, setResultData] = React.useState({});
  const [userID, setUserID] = useState(0);

  const SetDate = () => {
    let today = new Date();
    let year = today.getFullYear();
    let month = ('0' + (today.getMonth() + 1)).slice(-2);
    let day = ('0' + today.getDate()).slice(-2);
    let hours = ('0' + today.getHours()).slice(-2);
    let minutes = ('0' + today.getMinutes()).slice(-2);
    let seconds = ('0' + today.getSeconds()).slice(-2);
    dateString = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  };

  SetDate();
  useEffect(() => {
    let result = ResultData.find((s) => s.token === URLparams);
    setResultData(result)
    // try{
    //   PutResultData();
    // } catch (err) {
    //   this.errorCatch(err);
    // };
  }, [URLparams]);

  // DB 데이터 조회/삽입 중지 | 23-01-04 | GB
  // const getUserID = async() => {
  //   axios
  //   .get(UrlAPI)
  //   .then(response => {
  //       setUserID(response.data);
  //       PutResultData();
  //   });
  // }

  // const PutResultData = async() => {
  //   let params = {
  //     'userID' : (Number(userID) + 1).toString(),
  //     'date'   : dateString,
  //   };
  //   axios.post(UrlAPI, params);
  // };

  return (
    <Wrapper>

      <Header> 재벌집 막내 OO 테스트</Header>
      <Contents>
        {/* <Title>결과 </Title> */}
        <LogoImage>
          <img alt='img_result' src={resultData.image} width={300} height={300} className="rounded-circle" />
        </LogoImage>
        <Desc>당신은</Desc>
        <DescName>'{resultData.name}' 입니다!</DescName>
        {ResultText(resultData.id)}

        <br />
        <ButtonGroup>
          <Button style={{ marginRight: '10px' }} onClick={() => {
            navigate('/');
          }
          }>
            REPLAY
          </Button>
          <KakaoShareBtn style={{ marginLeft: '10px' }} data={{ data: resultData, pageID: 'resultPage' }} />
        </ButtonGroup>
          👇 다른 테스트 해보기 👇
          <img src={TestNYRimg} width={230} height={60} onClick={() => { window.open('https://newyearrabbit.click/'); }} /><br />
          <img src={TestKTAimg} width={230} height={60} onClick={() => { window.open('https://advancer-katalk.click/'); }} />
      </Contents>
        <br/>
      <Adfit height={100} width={320} unit={"Abv8ntzXLKbyGBAL"} scrFlag={true} position={"middle"}></Adfit>

      <Footer></Footer>
      <FooterWrapper>
        이 포스팅은 쿠팡 파트너스 활동의 일환으로, <br />이에 따른 일정액의 수수료를 제공받습니다.
      </FooterWrapper>
      <Adfit height={50} width={320} unit={"st2lERncEC99IpAh"} scrFlag={true} position={"buttom"}></Adfit>
    </Wrapper>
  );
}

export default Result;

const FooterWrapper = styled.div`
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  text-align: center
`

const Wrapper = styled.div`
  text-align: center
  height: 100%;
  width: 100%;
  font-family: "Tenada";
  background: linear-gradient(to bottom, black, #382001);
  white-space: pre-line;

`
const Header = styled.div`
  padding-top: 2rem;
  font-size: 27pt;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Tenada";
  font-weight: 500;
  color: white;
  text-align: center
`
const Contents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;

`
const Title = styled.div`
  font-size: 25px;
  margin-top: 40px;
`
const LogoImage = styled.div`
  margin-top: 20px;
`
const Desc = styled.div`
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 10px;
  white-space: pre-line;
`
const DescName = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  white-space: pre-wrap;

`
const DescSub = styled.div`
  font-size: 15px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5px;
  white-space: pre-wrap;
  font-family: "Welcome_Regular";

`
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`