import React from 'react';
// import React, { useEffect, useState } from 'react';
// const navigate = useNavigate();
// const [searchParams] = useSearchParams();
// const mbti = searchParams.get('mbti');
// const [resultData, setResultData] = React.useState({});

const Notfound = () => {
    window.location.replace("https://advancer-rrs.click/");
    
    console("workworkworkworkworkworkworkworkworkwork");
    return (<div>
        <h1>NotFound</h1>
    </div>    );
}

export default Notfound;