import React from 'react';
import Button from 'react-bootstrap/Button';
import POSTER from '../assets/poster.png';

const { Kakao } = window;

const KakaoShareBtn = ({ data }) => {
	const url = "https://advancer-rrs.click/";
	let ShareURL, ShareDesc, ShareImg, ShareTitle;

	if (data.pageID == 'startPage') {
		ShareURL = url;
		ShareDesc = "내가 환생한다면 재벌집 누구?";
		ShareImg = "https://advancer-rrs.click" + POSTER;
		ShareTitle = '테스트 하러가기';

	} else {
		ShareURL = url + `result/${data.data.token}`;
		ShareDesc = `당신은 ${data.data.name} 입니다!`
		ShareImg = "https://advancer-rrs.click" + data.data.image;
		ShareTitle = '친구 결과보고 테스트 해보기';
	}

	React.useEffect(() => {
		Kakao.cleanup();
		Kakao.init("06a6a2249ead31c4d0f4ddb7a7df607e");
	}, []);

	const shareKakao = () => {
		Kakao.Share.sendDefault({
			// window.Kakao.Link.sendDefault({
			objectType: 'feed',
			content: {
				title: '재벌집 막내 테스트 결과',
				imageUrl: encodeURI(ShareImg),
				link: {
					mobileWebUrl: ShareURL,
					webUrl: ShareURL,
				},
				description: ShareDesc,
			},
			social: {
				likeCount: 9876,
				sharedCount: 51088,
				viewCount: 102072,
			},
			buttons: [
				{
					title: ShareTitle,
					link: {
						mobileWebUrl: ShareURL,
						webUrl: ShareURL,
					},
				},
			]
		});
	}

	return (
		<Button
			style={{ backgroundColor: 'yellow', color: 'black', borderColor: 'yellow' }} onClick={shareKakao}>카카오톡 공유하기</Button>
	);
}

export default KakaoShareBtn;
