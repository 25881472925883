import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageImage from '../assets/person_img/dojun.jpg';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Footer from './footer';
import axios from 'axios';
import Adfit from "./Adfit";
import { UrlAPI } from './parameter';
import KakaoShareBtn from '../component/KakaoShareBtn';
import ReactDOM from 'react-dom'
import Snowfall from 'react-snowfall'

let accUserNum = 0;

const Home = () => {
  const navigate = useNavigate();
  /* DB 데이터 추출 중지 | 23-01-04 | GB
  const [userNum, setUserNum] = useState(0);
  const getUserNum = async () => {
    try {
      axios
        .get(UrlAPI)
        .then(response => {
          console.log(`사용자 수: ${response.data}`);
          setUserNum(response.data);
        })
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  useEffect(() => {
    getUserNum();
  }, [])
  
  */
  const handleClickButton = () => {
     navigate('/question');
  }

  const showUserNum = () => {
    return '5만 명+';
  };

  const resData = [
    {
      id: 1000,
      token: 'none',
      name: '***',
      best: 'ESTJ',
      desc: '***',
      image: PageImage,
    }];

    return(
    <div style={{ }}>
    <Snowfall snowflakeCount={50} wind={[0.5, 2.0]} />
    <Wrapper> 
      <Header>재벌집 막내 OO 테스트</Header>
      <Contents>
      <Title>나는 드라마속 어떤 인물?</Title>
        <br/>
        <LogoImage>
          <img alt='mainpage_img' src={PageImage} width={300} height={300} className="rounded-circle" />
        </LogoImage>
        <Desc>내가 진도준..?</Desc>
        <ButtonGroup>
        <Button style={{marginRight:'10px'}} onClick={handleClickButton}>START</Button>
        <KakaoShareBtn style={{marginLeft:'10px'}} data={{data: resData, pageID: 'startPage'}} />
        </ButtonGroup>
        </Contents>
        <Adfit height={100} width={320} unit={"Abv8ntzXLKbyGBAL"} scrFlag={true} position={"buttom"}></Adfit>
        <STitle>누적 환생 수 : {showUserNum()}</STitle>
        <Footer> </Footer>
    </Wrapper>
    </div>
    );
}


export default Home;

const Wrapper = styled.div `
  // height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "Tenada";
  background: linear-gradient(to bottom, black, #382001);
  text-align: center;
  white-space: pre-line;
`
const Header = styled.div `
padding-top: 2rem;
font-size: 27pt;
display: flex;
justify-content: center;
align-items: center;
font-family: "Tenada";
font-weight: 500;
color: white;
text-align: center
`
const Contents = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Title = styled.div `
  font-size: 25px;
  margin-top: 40px;
  color: white;
`

const STitle = styled.div `
  font-size: 17px;
  color: white;
`

const LogoImage = styled.div `
  margin-top: 20px;
`
const Desc = styled.div `
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  color: white;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`