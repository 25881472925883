import QImage1 from '../question_img/Q1.jpg';
import QImage2 from '../question_img/Q2.jpg';
import QImage3 from '../question_img/Q3.jpg';
import QImage4 from '../question_img/Q4.jpg';
import QImage5 from '../question_img/Q5.jpg';
import QImage6 from '../question_img/Q6.jpg';
import QImage7 from '../question_img/Q7.jpg';
import QImage8 from '../question_img/Q8.jpg';
import QImage9 from '../question_img/Q9.jpg';
import QImage10 from '../question_img/Q10.jpg';
import QImage11 from '../question_img/Q11.jpg';
import QImage12 from '../question_img/Q12.jpg';
import noticeImg from '../notice.png';

export const QuestionData = [
  {
    id: 1,
    title: "어린 진성준이, 진양철의 아끼는 도자기를 깨뜨렸다! 그걸 본 나의 속마음은?",
    answerA: "일단 나는 안했으니깐 뒤로 빠지고..",
    answerB: "안 다쳤을까? 엄청 날카로울텐데...",
    type: "TF",
    img: QImage1,
  },
  {
    id: 2,
    title: "할아버지가 '새우가 어부지리로 고래를 이길 방법' 을 묻는다. 나의 속마음은?",
    answerA:
      "할아버지를 잘 아실 것 같은\n집안 어른들께 여쭤본다",
	answerB:
      "혼자 생각해보다 정리되면\n말씀드리기로 마음먹는다",
    type: "EI",
    img: QImage2,
  },
  {
    id: 3,
    title: "\"와 니가 내 아들이고!?\"\n할아버지 진양철 회장이 아버지를 자식으로도 취급하지 않는다. 나의 속마음은?" ,
    answerA: "아버지가 무시받지 않게\n어떤 일을 하면 좋을지 고민한다.",
	  answerB: "화가 난다.\n아무말도 못하는 상황이 싫다.",
    type: "TF",
    img: QImage3,

  },
  {
    id: 4,
    title: "스시 장인으로 환생했다. 진양철 회장이 술상에 올릴 스시에 밥알은 280알만 하라고 지시한다. 나의 반응은?",
    answerA: "스시 장인인 내 감을 믿고 하던대로 일한다",
    answerB: "다음부턴 밥알을 확실히 세야겠다.",
    type: "SN",
    img: QImage4,

  },
  {
    id: 5,
    title:
      "수없이 많은 회의를 하고\n집에 돌아와 드는 생각은?",
	  answerA: "역시 사람은 사회생활을 해야해!\n이게 사람사는거지!",
    answerB: "일단 누워야겠다.\n기가 다 빨리는 하루였어",
    type: "EI",
    img: QImage5,

  },
  {
    id: 6,
    title: "\"와 내를 죽일라카는데..와!?\"\n진양철 회장이 섬망 증상을 보인다.\n나의 반응은?",
    answerA: "이사회 전까지 증상을 완화시킬 수는 없을까?",
    answerB: "호랑이 같던 카리스마를 잃은 할아버지 모습을 보니 마음이 아프다..",
    type: "TF",
    img: QImage6,

  },
  {
    id: 7,
    title: "금융지주사 설립 이사회 전날 밤,\n잠자리에 들기 전 드는 생각은?",
    answerA: "죽다 살아난 몸이다.\n내가 겁날 게 남아 있을 거 같더냐?!",
	  answerB: "혹시 다른 계략에 빠져들면 어쩌지?",
    type: "SN",
    img: QImage7,

  },
  {
    id: 8,
    title: "내일 순양백화점 공금 횡령 관련 회의가 11시에 열린다. 나는 ?",
    answerA:
      "회의 시작이 11시부터니깐 10시까지는 백화점에 도착해야겠다.",
	answerB:
      "회의에 증인을 준비했으니, 겁날게 없지🔥🔥🔥",
    type: "JP",
    img: QImage8,

  },
  {
    id: 9,
    title: "드디어 순양백화점의 대주주가 됐다.\n너무 힘들어서 하루는 쉬어야할것 같다.",
    answerA: "숙소를 알아보고 교통상황을 고려해\n집밖으로 나선다",
    answerB: "힐링하러 바다에 가고 싶어졌다.\n일단 바다로 가자!",
    type: "JP",
    img: QImage9,

  },
  {
    id: 10,
    title: "순양그룹의 미래를 위해 모현민과의\n선 자리에 가게 되었다. 나는 ?",
    answerA: "순양과 현성일보가 만나면\n후계자 자리는 내 것!",
	  answerB: "저런 배우자라면.. 내 아이들은 얼마나 사랑스럽고 야무질까?",
    type: "SN",
    img: QImage10,

  },
  {
    id: 11,
    title: "그렇게 맞선 자리에서 나는 ?",
    answerA:
      "\"어제 이런 일이 있었는데요~\"\n대화로 분위기를 이끈다.",
	  answerB: "\"하하 재밌네요~\" \n귀기울여 들어주며 대화를 진행한다",
    type: "EI",
    img: QImage11,

  },
  {
    id: 12,
    title: "\"할아버지. 제가 사려고요, 순양.\n순양을 살 생각입니다. 제 돈으로\"\n내 계획은?",
    answerA: "1997년엔 IMF가, 2002년엔 월드컵이 있을거니깐 이렇게 계획을 짜볼까나?",
    answerB: "IMF, 월드컵까지!\n그 때 그 때 대응하면 되겠군!",
    type: "JP",
    img: QImage12,
  },

  {
    id: 13,
    title : "안정적인 서버 운영비 조달을 위해 쿠팡 광고를 삽입했어요😅\n쿠팡 광고 뜨면 놀라지 마시고 뒤로 가기 버튼 누르시면 결과 확인하실 수 있어요🙏",
    answerA : "쿠팡 광고보고 결과 확인하기!",
    answerB : "X",
    type: "",
    img: noticeImg,
  }
];