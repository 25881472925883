import React from 'react';
import styled from 'styled-components';
import { ProgressBar, Button } from 'react-bootstrap';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { QuestionData } from '../assets/data/questiondata';
import Footer from './footer';
import Adfit from "./Adfit";
import "../assets/fonts/font.css"
import ReactDOM from 'react-dom'
import Snowfall from 'react-snowfall'

const Question = () => {
  const [mbti, setMbti] = React.useState();
  const [questionNo, setQuestionNo] = React.useState(0);
  const [totalScore, setTotalScore] = React.useState([
    { id : "EI", score: 0 },
    { id : "SN", score: 0 },
    { id : "TF", score: 0 },
    { id : "JP", score: 0 },
  ]);
  let mbtiResult;
  const navigate = useNavigate();
  const questionNum = 12;
  
  const handleClickBtn = (id, data, type) => {
      console.log(`id: ${id}`);
      const newScore = totalScore.map((s) => 
        s.id === type ? { id:s.id, score:s.score+data } : s
      );
      console.log(id);
      console.log(data);
      console.log(type);
      setTotalScore(newScore);
      if( id <= questionNum ) {
        setQuestionNo(questionNo+1);
        if (id == questionNum){
          mbtiResult = newScore.reduce(
            (acc, current) => 
              acc + (current.score >= 2 ? current.id.substring(0,1) : current.id.substring(1,2)),
              ""
          ); 
          setMbti(mbtiResult);
          console.log('mbti: ', mbti);
        }
      } 
  }
  
  const showQuestionNum = (id) => {
    if (id <= questionNum) return (<QuestionNo> Question.{id} </QuestionNo> )
  };

  const showTitle = (id) => {
    if (id <= questionNum) {return (<Title>{QuestionData[questionNo].title}</Title>);}
    else {return (<TitleSub>{QuestionData[questionNo].title}</TitleSub>)}
  }

  const ShowButton = (id) => {
    if (id <= questionNum){
    return (
      <ButtonGroup>
        <Button onClick={()=>handleClickBtn(id, 1, QuestionData[questionNo].type)} style={{width:"80%", minHeight: "15vh", fontSize:"17px", margin:"7px 7px 7px 7px"}}>{QuestionData[questionNo].answerA}</Button>
        <Button onClick={()=>handleClickBtn(id, 0, QuestionData[questionNo].type)} style={{width:"80%", minHeight: "15vh", fontSize:"17px", margin:"7px 7px 7px 7px"}}>{QuestionData[questionNo].answerB}</Button>
      </ButtonGroup>
    );
  } else {
    return (
      <ButtonGroup>
        <Button onClick={()=>Notice(mbti)} style={{"background-color": "green", "border": "none", width:"80%", minHeight: "15vh", fontSize:"17px", margin:"7px 7px 7px 7px"}}>{QuestionData[questionNo].answerA}</Button>
        <FooterWrapper>
        이 포스팅은 쿠팡 파트너스 활동의 일환으로, <br />이에 따른 일정액의 수수료를 제공받습니다. <br/><br/>
        <iframe src="https://ads-partners.coupang.com/widgets.html?id=712312&template=carousel&trackingCode=AF6214453&subId=&width=230&height=60&tsource=" width="230" height="60" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
      </FooterWrapper>
      </ButtonGroup>
    )
  }
  }

  const ShowAd1 = (id) => {
    if (id<=questionNum){
      return(
        <Adfit height={100} width={320} unit={"Abv8ntzXLKbyGBAL"} scrFlag={true} position={"middle"}></Adfit>
      );
    } 
  }
  
  const ShowAd2 = (id) => {
    if (id<=questionNum){
      return(
        <Adfit height={50} width={320} unit={"st2lERncEC99IpAh"} scrFlag={true} position={"buttom"}></Adfit>
        );
    } 
  }

  const Notice = (mbti) => {
      console.log('mbti: ', mbti);       
      window.open('https://link.coupang.com/a/8S4N0','_blank');
      navigate(`/result/${setURLparams(mbti)}`);
  }

  const setURLparams = (mbti) => {
    switch (mbti){
      case 'ESTJ':
        return 'cf4175fd7b'
      case 'ESFP':
        return 'ad0bbf2599'
      case 'ENTJ':
        return '40e482eaa0'
      case 'INTP':
        return 'a42b7064df'
      case 'INTJ':
        return '64334b1b4a'
      case 'ISTJ':
        return 'd2d63f789a'
      case 'ESTP':
        return 'b3b1d185e4'
      case 'INFP':
        return '0838bab7a4'
      case 'ISFP':
        return 'ad998fcad0'
      case 'ISFJ':
        return '15789b70e2'
      case 'ENFP':
        return 'bfaf148cd7'
      case 'INFJ':
        return '82791fa00c'
      case 'ESFJ':
        return '6ab5e4b717'
      case 'ENTP':
        return '61f4343a13'
      case 'ISTP':
        return 'bdd7320e1e'
      case 'ENFJ':
        return '0ac11dbcca' 
    }
      
  }
     return (
      <div style={{ }}>
      <Snowfall snowflakeCount={50} wind={[0.5, 2.0]} />
        <Wrapper>
            <Bar>
              <ProgressBar striped now={(questionNo/QuestionData.length)*100}/>
            </Bar>
            {showQuestionNum(QuestionData[questionNo].id)}
            <QImage>
              <img alt='question_img' src={QuestionData[questionNo].img}/>
            </QImage>
            {showTitle(QuestionData[questionNo].id)}
            {/* {ShowAd1(QuestionData[questionNo].id)} */}
            {ShowButton(QuestionData[questionNo].id)}
            <Footer></Footer>
            {ShowAd2(QuestionData[questionNo].id)}
        </Wrapper>
        </div>
    );
}

export default Question;


const FooterWrapper = styled.div`
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  text-align: center
`

const QImage = styled.div `
  text-align: center;
`

const Wrapper = styled.div `
  // height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "Tenada";
  background: linear-gradient(to bottom, black, #382001);
  text-align: center
  white-space: pre-line;

  `
const Bar = styled.div `
  padding-top: 2rem;
`
const QuestionNo = styled.div `
  margin: 2rem 1rem 1rem 1rem;
	font-size: 20px;
	text-align: center;
  font-weight: 500;
  color: white;

`
const Title = styled.div `
  padding: 1rem 1rem 1rem 1rem;
	font-size: 20px;
	text-align: center;
  color: white;
  white-space: pre-wrap;
  font-family: "Welcome_Regular";
`
const TitleSub = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  font-size: 17px;
  text-align: center;
  color: white;
  white-space: pre-wrap;
  font-family: "Welcome_Regular";
  `

const ButtonGroup = styled.div `
	display: flex;
	flex-direction : column;
	align-items: center;
	justify-content: center;
  white-space: pre-wrap;
  font-family: "Welcome_Regular";

`
