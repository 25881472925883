import React from 'react';
import styled from 'styled-components';

const Footer = () => {
    return (
        <FooterWrapper>
            ⓒ 2023. UF5 all rights reserved. v1.3.1<br/>
            📬 Contact: gbpark@uf5.io <br/>
        </FooterWrapper>
    );
}

export default Footer;

const FooterWrapper = styled.div `
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  text-align: center
`