import React from 'react';

const Blog = () => {
    window.location.replace("https://advancer-rrs.click/");
    
    console("workworkworkworkworkworkworkworkworkwork");
    return (<div>
        <h1>NotFound</h1>
    </div>    );
}

export default Blog;